import React, { useEffect, useRef, useState } from "react";
import styles from './ReveiwsSlider.module.scss'
import { IReveiw } from "../../Types/Models/types";

const ReveiwsSlider = () => {
    const sliderRef = useRef<HTMLDivElement>(null);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const slider = sliderRef.current;
        let animationId;
        const speed = 0.5; // Скорость движения (меньше 1)
        const slowSpeed = 0.2; // Скорость движения (меньше 1)
        let accumulatedScroll = 0; // Переменная для накопления дробных значений
        let accumulatedSlowScroll = 0; // Переменная для накопления дробных значений


        const moveSlider = () => {
            if (!slider) return;
            accumulatedScroll += speed; // Накопление скорости
            accumulatedSlowScroll += slowSpeed; // Накопление скорости
            if (!isHovered) {
                slider.scrollLeft += Math.floor(accumulatedScroll); // Прокручиваем на целое число
            }
            else {
                slider.scrollLeft += Math.floor(accumulatedSlowScroll); // Прокручиваем на целое число
            }
            accumulatedScroll %= 1; // Сохраняем остаток для дальнейшего накопления\
            accumulatedSlowScroll %= 1; // Сохраняем остаток для дальнейшего накопления\
            if (slider.scrollLeft >= slider.scrollWidth / 2) {
                slider.scrollLeft = 0; // Сброс в начало для бесконечности
            }
            animationId = requestAnimationFrame(moveSlider);
        };

        animationId = requestAnimationFrame(moveSlider);

        return () => cancelAnimationFrame(animationId);
    }, [isHovered]);

    const reveiws = [
        {
            logo: "/images/avito.jpg",
            rate: "5,0/5",
            link: "https://www.avito.ru/brands/i364874047/all/kvartiry?gdlkerfdnwq=101&shopId=4821913&page_from=from_item_card&iid=4253096031&context=&sellerId=19f16943bbd81ced372a7489020778c2"
        } as IReveiw,
        {
            logo: "/images/ostrovok.jpg",
            rate: "9,9/10",
            link: "https://ostrovok.ru/hotel/russia/moscow/mid11281598/cozy_aparts_aparthotel/?q=2395&dates=21.09.2024-22.09.2024&guests=2&sid=09fdefdc-e93e-4139-8198-a5e309cc4780"
        } as IReveiw,
        {
            logo: "/images/yandex.jpg",
            rate: "4,6/5",
            link: "https://yandex.ru/maps/org/cozy_aparts/119138966536/reviews/?indoorLevel=1&ll=37.535304%2C55.751584&tab=reviews&z=16.61"
        } as IReveiw,
        {
            logo: "/images/otello.jpg",
            rate: "5,0/5",
            link: "https://otello.ru/hotel/70000001088958110?adults=2&checkin=2025-01-28&checkout=2025-01-29"
        } as IReveiw,
        {
            logo: "/images/sutochno.jpg",
            rate: "10,0/10",
            link: "https://www.avito.ru/brands/i364874047/all/kvartiry?gdlkerfdnwq=101&shopId=4821913&page_from=from_item_card&iid=4253096031&context=&sellerId=19f16943bbd81ced372a7489020778c2"
        } as IReveiw,
        {
            logo: "/images/101hotels.jpg",
            rate: "5,0/5",
            link: "https://101hotels.com/opinions/hotel/moskva/apartamenti_cozy_aparts_moscow_city.html#reviews-container"
        } as IReveiw,
        {
            logo: "/images/avito.jpg",
            rate: "5,0/5",
            link: "https://www.avito.ru/brands/i364874047/all/kvartiry?gdlkerfdnwq=101&shopId=4821913&page_from=from_item_card&iid=4253096031&context=&sellerId=19f16943bbd81ced372a7489020778c2"
        } as IReveiw,
        {
            logo: "/images/ostrovok.jpg",
            rate: "9,9/10",
            link: "https://ostrovok.ru/hotel/russia/moscow/mid11281598/cozy_aparts_aparthotel/?q=2395&dates=21.09.2024-22.09.2024&guests=2&sid=09fdefdc-e93e-4139-8198-a5e309cc4780"
        } as IReveiw,
        {
            logo: "/images/yandex.jpg",
            rate: "4,6/5",
            link: "https://yandex.ru/maps/org/cozy_aparts/119138966536/reviews/?indoorLevel=1&ll=37.535304%2C55.751584&tab=reviews&z=16.61"
        } as IReveiw,
        {
            logo: "/images/otello.jpg",
            rate: "5,0/5",
            link: "https://otello.ru/hotel/70000001088958110?adults=2&checkin=2025-01-28&checkout=2025-01-29"
        } as IReveiw,
        {
            logo: "/images/sutochno.jpg",
            rate: "10,0/10",
            link: "https://www.avito.ru/brands/i364874047/all/kvartiry?gdlkerfdnwq=101&shopId=4821913&page_from=from_item_card&iid=4253096031&context=&sellerId=19f16943bbd81ced372a7489020778c2"
        } as IReveiw,
        {
            logo: "/images/101hotels.jpg",
            rate: "5,0/5",
            link: "https://101hotels.com/opinions/hotel/moskva/apartamenti_cozy_aparts_moscow_city.html#reviews-container"
        } as IReveiw,

    ] as IReveiw[]

    return (
        <div
            className={styles.slider_container}
            ref={sliderRef}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className={styles.slider_content}>
                {/* Элементы слайдера (дублируются для плавности) */}
                {Array.from({ length: 2 }).map((_, _index) =>
                    reveiws.map((reveiw,index) =>
                        <a href={reveiw.link} target="_black" key={reveiw.logo + _index + index} className={styles.SliderItem}>
                            <div className={styles.hovered}>
                                <button className={styles.Regular}>Смотреть отзывы</button>
                            </div>
                            <div className={styles.top}>
                                <span className={`${styles.rate} ${styles.Regular}`}>{reveiw.rate}</span>
                                {/* <img src="/images/arrow_link_black.svg" className={styles.arrow} /> */}
                            </div>
                            <img className={styles.Logo} src={reveiw.logo} />
                        </a>
                    )
                )}
            </div>
        </div>
    );
};

export default ReveiwsSlider;
