import React from 'react'
import styles from './FullService.module.scss'
import { IService } from '../../Types/Models/types'

interface IFullService {
    service: IService;
    setOpenedService: React.Dispatch<React.SetStateAction<IService | undefined>>;
    setWantedService?: React.Dispatch<React.SetStateAction<IService | undefined>>;
    addService?: (newService: IService) => void;
    removeService?: (removeService: IService) => void,
    isServicePicked?(service: IService): boolean,

}

const FullService: React.FC<IFullService> = ({ service, setOpenedService, setWantedService, addService, removeService, isServicePicked }) => {
    return (
        <div className={styles.FullService}>
            <button className={styles.close} onClick={() => {
                setOpenedService(undefined)
            }} />

            <p className={`${styles.Middle} ${styles.Bold}`}>Опция "{service.title}"</p>
            <p className={`${styles.Thin} ${styles.Text}`}>{service.text}</p>
            <p className={`${styles.price} ${styles.Regular}`}><span className={styles.Hide}>Стоимость услуги:</span> {service.price} ₽</p>
            {
                setWantedService &&
                <button className={styles.BookWithIt} onClick={() => {
                    setWantedService(service);
                    setOpenedService(undefined);
                }}>Забронировать c этой опцией</button>
            }
            {
                (addService && removeService && isServicePicked) &&
                (!isServicePicked(service) ?
                    <button onClick={() => {
                        addService(service);
                    }} className={`${styles.ToggleAdd} ${styles.Add} ${styles.Regular}`}>
                        Добавить услугу
                    </button>
                    :
                    <button onClick={() => {
                        removeService(service);
                    }} className={`${styles.ToggleAdd} ${styles.Delete} ${styles.Regular}`}>Убрать услугу</button>)
            }
        </div>
    )
}

export default FullService