import React, { useEffect, useRef, useState } from 'react'
import styles from './CardApart.module.scss'
import { IApart } from '../../Types/Models/types'

interface ICardApartProps {
    apart: IApart,
    device: number,
    pageWidth: number,
    paddingMobile: number,
    setWantedApart: React.Dispatch<React.SetStateAction<IApart | undefined>>
}

const CardApart: React.FC<ICardApartProps> = ({ apart, device, pageWidth, paddingMobile, setWantedApart }) => {

    const slider = useRef<HTMLDivElement>(null);
    const [canGoRightSlider, setCanGoRightSlider] = useState(true);
    const [canGoLeftSlider, setCanGoLeftSlider] = useState(false);
    const [currentFlat, setCurrentFlat] = useState(0);

    const updateScroll = () => {
        if (slider.current) {
            setCurrentFlat(Math.round(slider.current.scrollLeft / ((device > 1 ? 365 : (pageWidth - 2 * paddingMobile)) - 20)))
            if (slider.current?.scrollLeft > 0) {
                setCanGoLeftSlider(true);
            }
            else {
                setCanGoLeftSlider(false)
            }
            if (slider.current.scrollLeft + 40 < slider.current.scrollWidth - slider.current.clientWidth) {
                setCanGoRightSlider(true);
            }
            else {
                setCanGoRightSlider(false)
            }
        }
    }

    useEffect(() => {
        if (slider.current) {
            updateScroll();
        }
    }, [slider.current]);


    const handleScrollSlider = (right: boolean) => {
        if (slider.current) {
            let nextFlat = currentFlat;
            if (right && currentFlat < 4) {
                nextFlat = currentFlat + 1;
            }
            else if (!right && currentFlat > 0) {
                nextFlat = currentFlat - 1;
            }
            console.log(currentFlat, nextFlat);
            setCurrentFlat(nextFlat)
            if (slider.current) {
                slider.current.scrollTo({
                    left: ((device > 2 ? 366 : (pageWidth - 2 * paddingMobile))) * (nextFlat), // Полная ширина содержимого
                    behavior: 'smooth', // Гладкая анимация
                });
            }
        }
    }

    useEffect(() => {
        // console.log(currentFlat)
    }, [currentFlat])

    const niceRooms = (rooms) => {
        if (rooms == 1) {
            return rooms + " комната"
        }
        else if (rooms < 5) {
            return rooms + " комнаты"
        }
        else if (rooms === "Студия") {
            return "студия"
        }
        else return rooms + " комнат"
    }

    const ClickApart = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const target = e.target as HTMLElement
        if (target && (target.tagName === 'A' || target.tagName === 'BUTTON')) {
        } else {
            window.location.href = `/apartments/${apart.Name}`;
        }
    }

    return (
        <div key={apart.Name} onClick={ClickApart} className={styles.Apart}>
            <div className={styles.ImagesBlock}
                ref={slider}
                onScroll={updateScroll}>
                <div className={`${styles.SliderItem} ${styles[`pos${currentFlat}`]}`}>
                    {apart.images.map((image) =>
                        <div key={image} className={styles.ImageWrapper}>
                            <img src={image}></img>
                        </div>
                    )}
                    <div className={styles.MorePhotos}>
                        <img src={apart.images[0]} />
                        <a href={`/apartments/${apart.Name}`} className={styles.Regular}>Все фотографии</a>
                    </div>
                </div>
            </div>
            <div className={styles.Content}>
                <div className={styles.ImageButtons}>
                    <button onClick={() => handleScrollSlider(false)} className={`${styles.left}  ${!canGoLeftSlider ? styles.hide : ""}`}></button>
                    <button onClick={() => handleScrollSlider(true)} className={`${styles.right}  ${!canGoRightSlider ? styles.hide : ""}`}></button>
                </div>
                <span className={`${styles.Title} ${styles.Middle} ${styles.Bold}`}>{apart.Name}</span>
                <span className={styles.Description}>{apart.floor} этаж, {apart.area}м², {niceRooms(apart.rooms)}</span>
                <span className={`${styles.Price} ${styles.Bold}`}>от <span className={styles.Numbers}>{apart.price} ₽ </span> 
                {apart.discount && <span className={`${styles.discount}`}>{`${Math.floor(Number(apart.price.replace(",", "")) * 1.15)} ₽`}</span>}
                </span>
                <div className={styles.Buttons}>
                    <a className={`${styles.More} ${styles.Regular}`} href={`/apartments/${apart.Name}`}>Подробнее</a>
                    <button className={`${styles.Book} ${styles.Regular}`} onClick={() => {
                        setWantedApart(apart)
                    }}>Забронировать</button>
                </div>
            </div>
        </div>
    )
}

export default CardApart