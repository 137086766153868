import React, { useEffect, useRef, useState } from 'react'
import styles from './Home.module.scss'
import Header from '../../Components/Header/Header.tsx'
import { IApart, IApartRef, IInfrastructure, INavService, IReveiw, IService, services } from '../../Types/Models/types.tsx'
import { collection, getDocs } from 'firebase/firestore'
import { db, storage } from '../../firebase.js'
import { getDownloadURL, listAll, ref } from 'firebase/storage'
import CardApart from '../../Components/CardApart/CardApart.tsx'
import InfrastructureCard from '../../Components/InfrastructureCard/InfrastructureCard.tsx'
import ReveiwsSlider from '../../Components/ReveiwsSlider/ReveiwsSlider.tsx'
import Footer from '../../Components/Footer/Footer.tsx'
import FullService from '../../Components/FullService/FullService.tsx'
import Book from '../../Components/Book/Book.tsx'
import { useLocation } from 'react-router-dom'

interface IAllInfrastructures {
  rest: IInfrastructure[],
  bars: IInfrastructure[],
  spa: IInfrastructure[],
}

const Home = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  const [initialHeight, setInitialHeight] = useState(window.innerHeight)
  const mobilePadding = 25;
  const [pageWidth, setPageWidth] = useState(window.innerWidth)
  const [device, setDevice] = useState<number>(() => {
    const width = window.innerWidth;
    if (width <= 600) {
      return (0);
    }
    else if (width <= 792) {
      return (1)
    }
    else if (width <= 850) {
      return (2)
    }
    else if (width <= 1260) {
      return (3)
    }
    else return (4)
  });
  const [aparts, setAparts] = useState<IApart[]>();
  const [infrastrucures, setInfrastructures] = useState<IAllInfrastructures>({
    rest: [],
    bars: [],
    spa: [],
  })
  const [currentInfra, setCurrentInfra] = useState<string>("rest");
  const [pendingAparts, setPendingAparts] = useState(true);
  const [pendingInfra, setPendingInfra] = useState(true);
  const slider = useRef<HTMLDivElement>(null);
  const [canGoRightSliderInfra, setCanGoRightSliderInfra] = useState(true);
  const [canGoLeftSliderInfra, setCanGoLeftSliderInfra] = useState(false);
  const [currentIndexInfra, setCurrentIndexInfra] = useState(0);
  const [isOpenBook, setIsOpenBook] = useState(false);
  const [wantedApart, setWantedApart] = useState<IApart>();
  const [openedService, setOpenedService] = useState<IService>()
  const [wantedService, setWantedService] = useState<IService>();


  const nav_services = [
    { name: "Чем заняться в Москва Сити", icon: "/images/rest_black.svg", link: "#Infrasctructure" },
    { name: "Наши отзывы", icon: "/images/like_black.svg", link: "#Reveiws" },
    { name: "Контакты", icon: "/images/contacts.svg", link: "#Contacts" },
  ] as INavService[]

  const [scroll, setScroll] = useState(0)

  const updateObserver = () => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting)
          entry.target.classList.add(styles.animation_show)
        else {
          // entry.target.classList.remove(styles.animation_show)
        }
      });
    }, { threshold: 0.3 }); // 0.1 означает, что 10% элемента должно быть видно

    document.querySelectorAll(`.${styles.animation}`).forEach((i) => {
      if (i) {
        observer.observe(i);
      }
    });
  }

  useEffect(() => {
    updateObserver();
    const CheckDevice = () => {
      const width = window.innerWidth;
      setPageWidth(width);
      if (width <= 600) {
        setDevice(0);
      }
      else if (width <= 792) {
        setDevice(1)
      }
      else if (width <= 850) {
        setDevice(2)
      }
      else if (width <= 1260) {
        setDevice(3)
      }
      else setDevice(4)
    }
    const scroll = () => {
      setScroll(window.scrollY)
    }

    const tempAparts: IApart[] = [];
    const fetchAparts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "aparts"));
        const apartsData: IApartRef[] = querySnapshot.docs.map((doc) => ({
          ...doc.data()
        })) as IApartRef[];
        const tower = apartsData.findIndex((apart) => apart.tower === "Китай-город");

        if (tower !== -1) {
          const [targetObject] = apartsData.splice(tower, 1);
          apartsData.splice(2, 0, targetObject);
        }
        for (const myApartRef of apartsData) {
          const shortImgRef = ref(storage, myApartRef.img + "/Short");

          // Получаем список всех файлов в папке
          const shortImgs = await listAll(shortImgRef);

          // Получаем URL для каждого изображения
          const urlPromises = shortImgs.items.map(item => getDownloadURL(item));
          const urls = await Promise.all(urlPromises);

          if (urls) {
            const newApart = {
              tower: myApartRef.tower,
              price: myApartRef.price,
              area: myApartRef.area,
              floor: myApartRef.floor,
              Name: myApartRef.Name,
              description: myApartRef.description,
              images: urls,
              location: myApartRef.location,
              color: myApartRef.color,
              rooms: myApartRef.rooms,
              discount: myApartRef.discount
            } as IApart
            tempAparts.push(newApart);
          }
        }
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      } finally {
        // setCurrentScroll(Math.floor(tempAparts.length))
        setPendingAparts(false);
        setAparts(tempAparts);
      }
    };
    const fetchInrastructure = async () => {
      try {
        const querySnapshotRest = await getDocs(collection(db, "intert_rest"));
        const infraDataRest: IInfrastructure[] = querySnapshotRest.docs.map((doc) => ({
          ...doc.data()
        })) as IInfrastructure[];
        setInfrastructures((prev) => ({
          ...prev,
          rest: infraDataRest,
        }));

        const querySnapshotBars = await getDocs(collection(db, "inrert_bar"));
        const infraDataBars: IInfrastructure[] = querySnapshotBars.docs.map((doc) => ({
          ...doc.data()
        })) as IInfrastructure[];
        setInfrastructures((prev) => ({
          ...prev,
          bars: infraDataBars,
        }));

        const querySnapshotSpa = await getDocs(collection(db, "intert_spa"));
        const infraDataSpa: IInfrastructure[] = querySnapshotSpa.docs.map((doc) => ({
          ...doc.data()
        })) as IInfrastructure[];
        setInfrastructures((prev) => ({
          ...prev,
          spa: infraDataSpa,
        }));
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      } finally {
        setPendingInfra(false);
        updateObserver();
      }
    };
    fetchInrastructure();
    fetchAparts();

    CheckDevice();
    const updateHeight = () => {
      setInitialHeight(window.innerHeight)
    }
    window.addEventListener("orientationchange", updateHeight)
    window.addEventListener("resize", CheckDevice)
    document.addEventListener("scroll", scroll)
    return () => {
      window.removeEventListener("orientationchange", updateHeight)
      window.removeEventListener("resize", CheckDevice)
      document.removeEventListener("scroll", scroll);
    };
  }, [])

  useEffect(() => {
    updateObserver();
  }, [aparts, infrastrucures])

  const updateScrollInfra = () => {
    if (slider.current) {
      console.log(slider.current.scrollLeft)
      setCurrentIndexInfra(Math.floor(slider.current.scrollLeft / 400));
      if (slider.current?.scrollLeft > 0) {
        setCanGoLeftSliderInfra(true);
      }
      else {
        setCanGoLeftSliderInfra(false)
      }
      console.log(slider.current.scrollLeft, slider.current.scrollWidth, slider.current.clientWidth)
      if ((slider.current.scrollLeft + 20 < slider.current.scrollWidth - slider.current.clientWidth) || slider.current.scrollWidth === slider.current.clientWidth) {
        setCanGoRightSliderInfra(true);
      }
      else {
        setCanGoRightSliderInfra(false)
      }
    }
  }
  useEffect(() => {
    if (slider.current) {
      updateScrollInfra();
    }
  }, [slider.current]);

  const handleScrollSliderInfra = (right: boolean) => {
    if (slider.current) {
      if (right) {
        slider.current.scrollTo({
          left: 450 * (currentIndexInfra + 1), // Полная ширина содержимого
          behavior: 'smooth', // Гладкая анимация
        });
      }
      else {
        slider.current.scrollTo({
          left: 450 * (currentIndexInfra - 1), // Полная ширина содержимого
          behavior: 'smooth', // Гладкая анимация
        });
      }
    }
  }

  useEffect(() => {
    if (openedService || isOpenBook) {
      const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.overflowY = "hidden";
      document.body.style.paddingRight = `${scrollBarWidth}px`;
    }
    else if (!openedService && !isOpenBook) {
      document.body.style.overflowY = "";
      document.body.style.paddingRight = "";
    }
    if (!isOpenBook) {
      setWantedApart(undefined);
    }
  }, [openedService, isOpenBook])

  useEffect(() => {
    if (!isOpenBook) {
      setWantedService(undefined)
    }
  }, [isOpenBook])

  useEffect(() => {
    if (wantedApart || wantedService) {
      setIsOpenBook(true);
    }
  }, [wantedApart, wantedService])

  return (
    <div className={styles.Home} id='Main'>
      <Header home />
      {openedService &&
        <div className={styles.WrapperFullService}>
          <FullService setWantedService={setWantedService} service={openedService} setOpenedService={setOpenedService} />
        </div>
      }
      {isOpenBook &&
        <div className={styles.WrapperFullService}>
          <div className={styles.Inner}>
            <Book apart={wantedApart} wantedService={wantedService} setIsOpenBook={setIsOpenBook} />
          </div>
        </div>
      }
      <div className={`${styles.WelcomeBlock}`}>
        <img className={styles.Background} src='/images/main_PC.webp' />
        <img className={styles.BackgroundMobile} src='/images/main_Mobile.webp' />
        {/* <div className={styles.preHeader}> */}
        {/* <a href='#Aparts' className={`${styles.ButtonAparts} ${styles.Regular}`}>Выбрать Апартаменты</a> */}
        {/* <button className={`${styles.ButtonBook}`} onClick={() => {
            setIsOpenBook(true)
          }} ></button> */}
        {/* </div> */}
        <div className={styles.Content}>
          <img className={styles.LogoText} src='/images/logo_text_white.png'></img>
          <div className={styles.Text}>
            <span className={`${styles.Big} ${styles.Bold}`} >
              Апартаменты в Москва Сити посуточно
            </span>
            <span className={`${styles.mainText} ${styles.default} ${styles.Thin} `}>
              Проведите комфортный отдых или деловую встречу в центре мегаполиса с Cozy Aparts.
            </span>
            <div className={styles.ButtonsMain}>
              <a href='#Aparts' className={`${styles.ButtonAparts} ${styles.Regular}`}>Выбрать Апартаменты</a>
              <a href='#Services' className={`${styles.Regular} ${styles.Services}`}>Наши услуги</a>
            </div>
            {/* <button className={`${styles.Bold} ${styles.MainBook}`} onClick={() => {
            setIsOpenBook(true)
          }} >Оставить заявку на бронирование</button> */}
            {/* <a className={`${styles.ButtonMore} ${scroll >= 100 ? styles.hide : ""}`} href='#Services'>
          </a> */}
          </div>
          <div className={styles.BookMainWrapper}>
            <Book noHeight={true} />
          </div>
        </div>
      </div>
      <div className={styles.ServiceBlock} id='Services'>
        <div className={styles.Content}>
          <div className={styles.TopBlock}>
            <span className={`${styles.BlockTitle} ${styles.Big} ${styles.Bold} ${styles.animation}`}>Услуги Cozy Aparts</span>
            <a href='#Aparts' className={`${styles.GoAparts} ${styles.animation}`}>{`К выбору апартаментов`}</a>
          </div>
          <div className={`${styles.Services}`}>
            {services.map((item, index) => (
              <div key={item.title} style={{
                transitionDelay: index % 3 * 0.1 + "s",
              }} className={`${styles.Service} ${item.className ? styles[item.className] : ""} ${styles.animation}`}>
                <img src={item.image} alt={item.title} className={styles.Icon} />
                <p className={`${styles.Middle} ${styles.Bold}`}>{item.title}</p>
                <p className={`${styles.Thin}`}>{item.shortText}</p>
                <img className={styles.Background} src={item.background}></img>
                <button onClick={() => {
                  setOpenedService(item)
                }} className={styles.Expand} />
              </div>
            ))}
          </div>
          <nav className={styles.ServiceNavs}>
            <ul>
              {nav_services.map((item) =>
                <li key={item.name} className={`${styles.Nav} ${styles.animation}`}>
                  <a href={item.link}>
                    {/* <img className={styles.Icon} src={item.icon}></img> */}
                    <span className={`${styles.Middle} ${styles.Regular}`}>{item.name}</span>
                    <img className={styles.Arrow} src='/images/arrow_black.svg' />
                  </a>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
      <div className={`${styles.ApartsBlock} ${pendingAparts ? styles.Pending : ""}`} id='Aparts'>
        <div className={`${styles.Content}`}>
          <div className={styles.TopBlock}>
            <span className={`${styles.BlockTitle} ${styles.Big} ${styles.Bold} ${styles.animation}`}>Коллекция апартаментов</span>
            <div className={`${styles.Info} ${styles.animation}`}>
              <img src='/images/sleep_black.svg' />
              <span>Оставьте заявку на бронирование на сайте, и мы свяжемся с Вами в самое ближайшее время. Либо
                свяжитесь с нами и назовите апартаменты, которые вам понравились.
              </span>
            </div>
          </div>
          {pendingAparts ?
            <div className={`${styles.animation} ${styles.LoadingBlock}`}>
              <img src='/images/loading.svg'></img>
            </div>
            :
            <div className={`${styles.Aparts}`}>
              {aparts?.map((apart, index) =>
                <div key={apart.Name} className={`${styles.CardWrapper} ${styles.animation}`} style={{
                  transitionDelay: index % 3 * 0.1 + "s",
                }}>
                  <CardApart setWantedApart={setWantedApart} device={device} pageWidth={pageWidth} paddingMobile={mobilePadding} apart={apart} />
                </div>
              )}
            </div>
          }
        </div>
      </div>
      <div className={`${styles.InfrascructureBlock} ${pendingInfra ? styles.Pending : ""}`} id='Infrasctructure'>
        <div className={styles.TopBlock}>
          <span className={`${styles.BlockTitle} ${styles.Big} ${styles.Bold} ${styles.animation}`}>Инфраструктура Сити</span>
          <div className={`${styles.InfraButtons} ${styles.animation}`}>
            {[{ name: "Рестораны", ref: "rest" }, { name: "Бары", ref: "bars" }, { name: "СПА", ref: "spa" }].map((infra, index) =>
              <button key={infra.name} onClick={() => {
                setCurrentInfra(infra.ref)
                if (slider.current) {
                  slider.current.scrollLeft = 0;
                }
              }}
                className={`${styles[infra.ref]} ${currentInfra === infra.ref ? styles.infra_active : ""}`}
              >{infra.name}</button>
            )}
          </div>
        </div>
        {pendingInfra ?
          <div className={`${styles.LoadingBlock} ${styles.animation}`}>
            <img src='/images/loading.svg'></img>
          </div>
          :
          <div className={`${styles.Content} ${styles.animation}`} ref={slider} onScroll={updateScrollInfra}>
            <div className={styles.Infras}>
              <div className={styles.Slider} style={{
                // width: 366 * infrastrucures[currentInfra].length + 50 * (infrastrucures[currentInfra].length - 1) + "px"
              }}>
                <div className={styles.SlideContent}>
                  {infrastrucures[currentInfra].map((infra, index) =>
                    <div className={`${styles.WrapperInfra}`} key={infra.Name}>
                      <InfrastructureCard key={infra.Name} infrastructure={infra} icon={currentInfra} index={index} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        }
        <div className={styles.ButtonsBlock}>
          <button onClick={() => handleScrollSliderInfra(false)} className={`${styles.LeftButton} ${!canGoLeftSliderInfra ? styles.hide : ""}`}></button>
          <button onClick={() => handleScrollSliderInfra(true)} className={`${styles.RightButton} ${!canGoRightSliderInfra ? styles.hide : ""}`}></button>
        </div>
      </div>
      <div className={`${styles.ReveiwsBlock}`} id='Reveiws'>
        <div className={styles.TopBlock}>
          <span className={`${styles.BlockTitle} ${styles.Big} ${styles.Bold} ${styles.animation}`}>Наши отзывы</span>
          <div className={`${styles.Info} ${styles.animation}`}>
            <img src='/images/info.svg' />
            <span>Мы размещаем ссылки на площадки бронирования, чтобы Вы могли ознакомиться с отзывами наших гостей и убедиться в качестве наших апартаментов.
              Однако, бронируя через наш сайт напрямую, Вам не придется оплачивать дополнительные комиссии, которые взимаются на сторонних платформах.</span>
          </div>
        </div>
        <div className={`${styles.Content} ${styles.animation}`}>
          <ReveiwsSlider />
        </div>
      </div>
      <div className={styles.ContantsBlock} id='Contacts'>
        <div className={styles.Content}>
          <div className={styles.TopBlock}>
            <span className={`${styles.BlockTitle} ${styles.Big} ${styles.Bold} ${styles.animation}`}>Контакты и правила</span>
          </div>
          <div className={`${styles.Blocks} ${styles.animation}`}>
            <div className={`${styles.Block} ${styles.Contacts}`}>
              <div className={styles.Top}>
                <img src='/images/contacts.svg' className={styles.icon}></img>
                <span className={`${styles.Middle} ${styles.Bold}`}>Контакты</span>
                <ul className={`${styles.Text} ${styles.ContactsList}`}>
                  <li className={styles.phone}><a href='tel:+79299023710' target='_blank'>+7 929 902 3710</a></li>
                  <li className={styles.tg}><a href='https://t.me/cozzyaparts' target='_blank'>@cozzyaparts</a></li>
                  <li className={styles.mail}><a href='mailto:cozyaparts@yandex.ru' target='_blank'>cozyaparts@yandex.ru</a></li>
                </ul>
              </div>
              <div className={styles.bottom}>
                <a href='https://t.me/cozzyaparts' target='_blank'><img src='/images/tg_full.svg' /></a>
                <a href="https://wa.me/79299023710" target="_blank"><img src='/images/whatsapp_full.svg' /></a>
              </div>
            </div>
            <div className={`${styles.Block} ${styles.Rules}`}>
              <div className={styles.Top}>
                <div className={styles.Left}>
                  <img src='/images/rules.svg' className={styles.icon}></img>
                  <span className={`${styles.Middle} ${styles.Bold}`}>Правила заселения</span>
                  <span className={`${styles.Text}`}>
                    Цена в бронировании указана до 2-х человек, сверх этого количества доплата за гостей - 1000₽/гость/сутки<br /><br />
                    Заезд с 15:00<br />
                    Выезд до 12:00<br /><br />
                    Поздний выезд и ранний заезд возможен по договорённости. Стоимость - 900₽/час<br /><br />
                    <span className={`${styles.Bold} ${styles.Middle}`} style={{
                      marginBottom: "15px",
                      display: "inline-block",
                      marginTop: "15px"
                    }}>Залог </span><br />
                    10,000₽ - стандартный<br />
                    20,000₽ - при проведении мероприятий<br /><br />
                  </span>
                </div>
                <div className={styles.Right}>
                  <span className={`${styles.Middle} ${styles.Bold}`}>Парковка</span>
                  <span className={`${styles.Text}`}>
                    Вы можете оставить машину в башне "Neva Towers 1". Стоимость парковки: 4,500₽/сутки<br /><br />
                    <span className={`${styles.Bold} ${styles.Middle}`} style={{
                      marginBottom: "15px",
                      display: "inline-block",
                      marginTop: "15px"
                    }}>Документы </span><br />
                    Во всех башнях проход по документам. Убедитесь, что у вас с собой паспорт или водительское удостоверение. <br /> <br />
                    Для детей младше 14 лет - свидетельство о рождении<br /><br />
                    Отчетные документы предоставляются по запросу
                  </span>
                </div>
              </div>
              <div className={styles.bottom}>
                <a href='#Aparts' className={styles.Regular}>Выбрать Апартаменты</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Home