import React, { useEffect, useState } from 'react'
import styles from './Header.module.scss'

interface IHeaderProps {
    home?: boolean
}

const Header: React.FC<IHeaderProps> = ({ home }) => {
    const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
    const Nav = () => {
        return (
            <>
                <li><a onClick={() => {
                    setIsOpenMobileMenu(false);
                }} href={`${window.location.origin}/#Main`}>Главная</a></li>
                <li><a onClick={() => {
                    setIsOpenMobileMenu(false);
                }} href={`${window.location.origin}/#Services`}>Услуги</a></li>
                <li><a onClick={() => {
                    setIsOpenMobileMenu(false);
                }} href={`${window.location.origin}/#Aparts`}>Бронирование</a></li>
                <li><a onClick={() => {
                    setIsOpenMobileMenu(false);
                }} href={`${window.location.origin}/#Infrasctructure`}>Инфраструтура</a></li>
                <li><a onClick={() => {
                    setIsOpenMobileMenu(false);
                }} href={`${window.location.origin}/#Reveiws`}>Отзывы</a></li>
                <li><a onClick={() => {
                    setIsOpenMobileMenu(false);
                }} href={`${window.location.origin}/#Contacts`}>Контакты</a></li>
            </>
        )
    }

    useEffect(() => {
        if (isOpenMobileMenu) {
            const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.overflowY = "hidden";
            document.body.style.paddingRight = `${scrollBarWidth}px`;
        }
        else if (!isOpenMobileMenu) {
            document.body.style.overflowY = "";
            document.body.style.paddingRight = "";
        }
    }, [isOpenMobileMenu])
    return (
        <>
            <div className={`${styles.Header} ${home ? styles.Home : ""}`}>
                <div className={styles.HeaderInner}>
                    <a className={`${styles.tg} ${styles.tgHomeTablet}`} href="https://t.me/cozzyaparts" target="_blank" />
                    <a className={styles.Logo} href='/'>
                        <img src='/images/logo_header.png' className={styles.logo}></img>
                    </a>
                    <a className={styles.HomePhone} href='tel:+79299023710'>+7 929 902 37 10</a>
                    <nav className={styles.menu}>
                        <ul>
                            <Nav />
                        </ul>
                    </nav>
                    <div className={styles.contants}>
                        <a className={styles.Phone} href='tel:+79299023710'>+7 929 902 37 10</a>
                        <a className={styles.tg} href="https://t.me/cozzyaparts" target="_blank" />
                    </div>
                    <button className={styles.OpenMobile} onClick={() => {
                        setIsOpenMobileMenu(true);
                    }}>
                        <img src='/images/menu_black.svg'></img>
                    </button>
                </div>
            </div>
            {isOpenMobileMenu && <div className={styles.MobileMenu}>
                <ul>
                    <button className={styles.Close} onClick={() => {
                        setIsOpenMobileMenu(false);
                    }} />
                    <nav className={`${styles.menu} ${styles.Regular} ${styles.Middle}`}>
                        <ul>
                            <Nav />
                            <li className={styles.contacts}>
                                <a className={`${styles.image} ${styles.number}`} href='tel:+79299023710'></a>
                                <a className={`${styles.image} ${styles.tg}`} href="https://t.me/cozzyaparts" target="_blank" />
                            </li>
                        </ul>
                    </nav>
                </ul>
            </div>}
        </>
    )
}

export default Header