export interface IService {
    image: string,
    title: string,
    shortText,
    text: string,
    price: string,
    className: string,
    background: string,
}

export interface INavService {
    icon: string,
    name: string,
    link: string,
}

export interface IReveiw {
    logo: string,
    link: string,
    rate: string,
}

export interface IApartRef {
    img: string,
    tower: string,
    price: string,
    area: string,
    floor: string,
    Name: string,
    description: string,
    location: string,
    color: string,
    rooms: string,
    discount: boolean
}
export interface IApart {
    images: string[],
    tower: string,
    price: string,
    area: string,
    floor: string,
    Name: string,
    description: string,
    location: string,
    color: string,
    rooms: string,
    view: string,
    sleep: string,
    discount: boolean
}
export interface IInfrastructure {
    Image: string,
    Name: string,
    Descr: string,
    Map: string,
}
export interface IFooterLinkBlock {
    name: string,
    fields: IFooterField[],
}
export interface IFooterField {
    name: string,
    link: string,
    icon?: string,
    blank?: boolean,
}
export const services = [
    {
        image: "/images/play.svg",
        title: "Gamer Pack",
        shortText: "Для любителей игр — PlayStation 5 с огромной коллекцией лучших игр на любой вкус! Идеальные условия для отдыха и развлечений.",
        text: "Для любителей игр — PlayStation 5 с огромной коллекцией лучших игр на любой вкус! Идеальные условия для отдыха и развлечений.  Вносится дополнительный залог в размере 5000Р, который возвращается в день выезда до 18:00. По поводу наличия игр уточняйте у администраторов.",
        price: "5,000",
        className: "Play",
        background: "/images/ps_full.png"
    } as IService,
    {
        image: "/images/romantic.svg",
        title: "Romantic collection",
        shortText: "Создайте атмосферу волшебства и уюта с нашим эксклюзивным романтическим оформлением апартаментов! Мы украсим ваше пространство лепестками роз, добавим мягкую подсветку и зажжем свечи",
        text: "Создайте атмосферу волшебства и уюта с нашим эксклюзивным романтическим оформлением апартаментов! Мы украсим ваше пространство лепестками роз, добавим мягкую подсветку и зажжем свечи, чтобы создать идеальные условия для романтического вечера. Оформление обсуждается индивидуально с гостем. Оставьте заявку на бронирование и укажите ваши пожелания, какими вы хотите видеть апартаменты",
        price: "10,000",
        className: "Romantic",
        background: "/images/vine_full.png"
    } as IService,
    {
        image: "/images/sertificate.svg",
        title: "Gift Sertificate",
        shortText: "Ваучер на проживании в апартаментах Москва Сити. Отличный подарок для близкого человека, который подарит незабываемые эмоции",
        text: "Ваучер на проживании в апартаментах Москва Сити. Отличный подарок для близкого человека, который даст незабываемые эмоции. Ваучер действует 3 месяца с момента приобретения. По данному сертификату вам будут доступны все апартаменты из нашей коллекции. Ваучер дублируется в электронном и печатном виде",
        price: "20,000",
        className: "Sert",
        background: "/images/sert_full.png"
    } as IService,
] as IService[]