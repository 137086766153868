import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './Pages/Home/Home.tsx';
import Apartment from './Pages/Apartment/Apartment.tsx';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/apartments/:nameApart",
    element: <Apartment />,
  },
  {
    path: "/",
    element: <Home />
  },
]);

root.render(
  <RouterProvider router={router}></RouterProvider>
  // <RouterProvider router={router} />
);
