import React, { useEffect, useRef, useState } from 'react'
import styles from './Gallery.module.scss'

interface IGalleryProps {
    images: string[];
    device: number;
    startImage: number;
    setIsOpenGallery: React.Dispatch<React.SetStateAction<boolean>>
}

const Gallery: React.FC<IGalleryProps> = ({ images, device, startImage, setIsOpenGallery }) => {

    const slider = useRef<HTMLDivElement>(null);
    const [canGoRightSlider, setCanGoRightSlider] = useState(true);
    const [canGoLeftSlider, setCanGoLeftSlider] = useState(false);
    const [currentImage, setCurrentImage] = useState(startImage)
    const mobileWidth = window.innerWidth;
    const PCWidth = (window.innerHeight - 100) * 4 / 3;
    const updateScroll = () => {
        if (slider.current) {
            setCurrentImage(Math.round(slider.current.scrollLeft / ((window.innerWidth > PCWidth ? PCWidth : mobileWidth))))
        }
    }

    useEffect(() => {
        setCurrentImage(startImage);
        setTimeout(() => {
            if (slider.current) {
                slider.current.scrollTo({
                    left: ((window.innerWidth > PCWidth ? PCWidth : mobileWidth)) * (startImage), // Полная ширина содержимого
                });
            }
        }, 0)
    }, [startImage])
    useEffect(() => {
        setCanGoLeftSlider(currentImage > 0);
        setCanGoRightSlider(currentImage < images.length - 1);
    }, [currentImage])
    const handleScrollSlider = (right: boolean) => {
        if (slider.current) {
            let nextImage = currentImage;
            if (right && (currentImage < images.length - 1)) {
                nextImage = currentImage + 1;
            }
            else if (!right && currentImage > 0) {
                nextImage = currentImage - 1;
            }
            setCurrentImage(nextImage)
            if (slider.current) {
                slider.current.scrollTo({
                    left: ((window.innerWidth > PCWidth ? PCWidth : mobileWidth)) * (nextImage), // Полная ширина содержимого
                    behavior: 'smooth', // Гладкая анимация
                });
            }
        }
    }
    return (
        <div className={styles.Gallery}>
            <div className={styles.WrapperImagesBlock}>

                <div className={styles.ImagesBlock} ref={slider}
                    onScroll={updateScroll}>
                    <div className={styles.ScrollItem} style={{
                        width: images.length * (window.innerWidth > PCWidth ? PCWidth : mobileWidth) + "px"
                    }}>
                        {images.map((image, i) =>
                            <div key={"image" + i} className={styles.ImageWrapper}>
                                <img className={styles.Image} src={image} />
                            </div>
                        )}
                    </div>
                </div>
                <button className={styles.Close} onClick={() => {
                    setIsOpenGallery(false)
                }}></button>
                <button onClick={() => handleScrollSlider(false)} className={`${styles.buttonSwipe} ${styles.leftSwipe}  ${!canGoLeftSlider ? styles.hide : ""}`}></button>
                <button onClick={() => handleScrollSlider(true)} className={`${styles.buttonSwipe} ${styles.rightSwipe}  ${!canGoRightSlider ? styles.hide : ""}`}></button>
            </div>
        </div>
    )
}

export default Gallery