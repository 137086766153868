import React from 'react'
import { IFooterField, IFooterLinkBlock } from '../../Types/Models/types'

import styles from './Footer.module.scss'

const Footer = () => {

    const footer_fields = [
        {
            name: "Контакты",
            fields: [
                { name: "+7 929 902 3710", link: "tel:+79299023710", icon: "/images/phone_black.svg" } as IFooterField,
                { name: "@cozyaparts", link: "https://t.me/cozzyaparts", icon: "/images/tg_black.svg" } as IFooterField,
                { name: "@cozyaparts@mail.ru", link: "mailto:cozyaparts@yandex.ru", icon: "/images/mail_black.svg" } as IFooterField,
            ] as IFooterField[]
        } as IFooterLinkBlock,
        {
            name: "Меню",
            fields: [
                { name: "Главная", link: `${window.location.origin}/#Main` } as IFooterField,
                { name: "Услуги", link: `${window.location.origin}/#Services` } as IFooterField,
                { name: "Апартаменты", link: `${window.location.origin}/#Aparts` } as IFooterField,
                { name: "Инфраструктура", link: `${window.location.origin}/#Infrasctructure` } as IFooterField,
                { name: "Отзывы", link: `${window.location.origin}/#Reveiws` } as IFooterField,
                { name: "Контакты", link: `${window.location.origin}/#Contacts` } as IFooterField,
            ] as IFooterField[]
        } as IFooterLinkBlock,
        {
            name: "Отзывы",
            fields: [
                { name: "Avito", link: "https://www.avito.ru/brands/i364874047/all/kvartiry?gdlkerfdnwq=101&shopId=4821913&page_from=from_item_card&iid=4253096031&context=&sellerId=19f16943bbd81ced372a7489020778c2", blank: true } as IFooterField,
                { name: "Ostrovok", link: "https://ostrovok.ru/hotel/russia/moscow/mid11281598/cozy_aparts_aparthotel/?q=2395&dates=21.09.2024-22.09.2024&guests=2&sid=09fdefdc-e93e-4139-8198-a5e309cc4780", blank: true } as IFooterField,
                { name: "Yandex карты", link: "https://yandex.ru/maps/org/cozy_aparts/119138966536/reviews/?indoorLevel=1&ll=37.535304%2C55.751584&tab=reviews&z=16.61", blank: true } as IFooterField,
                { name: "Otello", link: "https://otello.ru/hotel/70000001088958110?adults=2&checkin=2025-01-28&checkout=2025-01-29", blank: true } as IFooterField,
                { name: "Sutochno", link: "https://www.avito.ru/brands/i364874047/all/kvartiry?gdlkerfdnwq=101&shopId=4821913&page_from=from_item_card&iid=4253096031&context=&sellerId=19f16943bbd81ced372a7489020778c2", blank: true } as IFooterField,
                { name: "101Hotels", link: "https://101hotels.com/opinions/hotel/moskva/apartamenti_cozy_aparts_moscow_city.html#reviews-container", blank: true } as IFooterField,
            ] as IFooterField[]
        } as IFooterLinkBlock,
    ] as IFooterLinkBlock[]

    return (
        <div className={styles.Footer}>
            <div className={styles.Content}>
                <div className={styles.Top}>
                    {footer_fields.map((block) =>
                        <div key={block.name} className={`${styles.Block} ${styles[block.name]}`}>
                            <span className={styles.Title}>{block.name}</span>
                            {
                                block.fields.map((field) =>
                                    <div key={field.name} className={styles.Field}>
                                        {field.icon && <img src={field.icon} />}
                                        <a href={field.link} target={`${field.blank && "_blank"}`} >{field.name}</a>
                                    </div>
                                )
                            }
                        </div>
                    )}
                </div>
                <div className={styles.Bottom}>
                    <span>Copyright 2025 © Cozy Aparts</span>
                    <div className={styles.Infos}>
                        <a href='#'>Правила оказания услуг</a>
                        <span>{` | `}</span>
                        <a href='#'>Политика обработки персональных данных</a>
                    </div>
                    <span>Россия, Москва</span>
                </div>
                <div className={styles.BottomMobile}>
                    <div className={styles.One}>
                        <span>Copyright 2025 © Cozy Aparts</span>
                        <span>Россия, Москва</span>
                    </div>
                    <div className={styles.Infos}>
                        <a href='#'>Правила оказания услуг</a>
                        <span>{` | `}</span>
                        <a href='#'>Политика обработки персональных данных</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer