import React, { useState } from 'react'
import styles from './AddServices.module.scss'
import { IService, services } from '../../Types/Models/types.tsx'
import FullService from '../FullService/FullService.tsx'

interface IAddServices {
    setIsOpenPickServices: React.Dispatch<React.SetStateAction<boolean>>,
    pickedServices: IService[],
    addService: (newService: IService) => void,
    removeService: (removeService: IService) => void,

}

const AddServices: React.FC<IAddServices> = ({ setIsOpenPickServices, pickedServices, addService, removeService }) => {

    function isServicePicked(service: IService) {
        return pickedServices.some((item) => item.title === service.title);
    }

    const [openedService, setOpenedService] = useState<IService>()
    return (
    <>
        <div className={`${openedService ? styles.HideThis : ""} ${styles.AddServices}`}>
            {services.map((service) =>
                <div key={service.title} className={styles.Service}>
                    <button className={styles.close} onClick={() => {
                        setIsOpenPickServices(false)
                    }} />
                    <p className={`${styles.Middle} ${styles.Bold}`}>{service.title}</p>
                    <p className={`${styles.Thin} ${styles.Text}`}>{service.shortText}</p>
                    <div className={styles.Buttons}>
                        {
                            !isServicePicked(service) ?
                                <button onClick={() => {
                                    addService(service)
                                }} className={styles.Add} />
                                :
                                <button
                                    onClick={() => {
                                        removeService(service)
                                    }}
                                    className={styles.Delete} />
                        }
                        <button className={styles.More} onClick={() => {
                            setOpenedService(service)
                        }}>Подробнее</button>
                    </div>
                </div>
            )}
        </div>
            {openedService &&
                <div className={styles.WrapperFullService}>
                    <FullService removeService={removeService} isServicePicked={isServicePicked} addService={addService} service={openedService} setOpenedService={setOpenedService} />
                </div>
            }
        </>
    )
}

export default AddServices