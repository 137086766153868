import React, { useEffect, useRef, useState } from 'react'
import styles from './Apartment.module.scss'
import { IApart } from '../../Types/Models/types'
import { useParams } from 'react-router-dom'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db, storage } from '../../firebase'
import { getDownloadURL, listAll, ref } from 'firebase/storage'
import Header from '../../Components/Header/Header.tsx'
import ReveiwsSlider from '../../Components/ReveiwsSlider/ReveiwsSlider.tsx'
import Footer from '../../Components/Footer/Footer.tsx'
import Book from '../../Components/Book/Book.tsx'
import Gallery from '../../Components/Gallery/Gallery.tsx'

const skeletonApart =
  {
    images: [" ", " ", " ", " ", " ", " ", " "],
    tower: "",
    price: "",
    area: "",
    floor: "",
    Name: "",
    description: "",
    rooms: "",
    view: "",
    sleep: "",
    location: "",
    color: "",
  } as IApart

const Apartment = () => {
  const mobilePadding = 25;
  const [pageWidth, setPageWidth] = useState(window.innerWidth)
  const [device, setDevice] = useState<number>(() => {
    const width = window.innerWidth;
    if (width <= 600) {
      return (0);
    }
    else if (width <= 700) {
      return (1)
    }
    else if (width <= 850) {
      return (2)
    }
    else if (width <= 1260) {
      return (3)
    }
    else return (4)
  });
  const apartName = useParams().nameApart;
  const [apart, setApart] = useState<IApart>(skeletonApart);
  const [isPending, setIsPending] = useState(true);

  const slider = useRef<HTMLDivElement>(null);
  const [canGoRightSlider, setCanGoRightSlider] = useState(true);
  const [canGoLeftSlider, setCanGoLeftSlider] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isOpenBookMobile, setIsOpenBookMobile] = useState(false);
  const [isOpenGallery, setIsOpenGallery] = useState(false);
  const [wantedImage, setWantedImage] = useState(0);

  const updateObserver = () => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting)
          entry.target.classList.add(styles.animation_show)
        else {
          // entry.target.classList.remove(styles.animation_show)
        }
      });
    }, { threshold: 0.2 }); // 0.1 означает, что 10% элемента должно быть видно

    document.querySelectorAll(`.${styles.animation}`).forEach((i) => {
      if (i) {
        observer.observe(i);
      }
    });
  }

  const updateScroll = () => {
    if (slider.current) {
      setCurrentImage(Math.round(slider.current.scrollLeft / ((device > 1 ? 700 : (pageWidth - 2 * mobilePadding)))))
    }
  }
  useEffect(() => {
    console.log(currentImage);
    setCanGoLeftSlider(currentImage > 0);
    setCanGoRightSlider(currentImage < apart.images.length - 1);
  }, [currentImage])

  useEffect(() => {


    updateObserver();
    const CheckDevice = () => {
      const width = window.innerWidth;
      setPageWidth(width);
      if (width <= 600) {
        setDevice(0);
      }
      else if (width <= 792) {
        setDevice(1)
      }
      else if (width <= 850) {
        setDevice(2)
      }
      else if (width <= 1260) {
        setDevice(3)
      }
      else setDevice(4)
    }

    const fetchAparts = async () => {
      try {
        const collectionRef = collection(db, 'aparts'); // Замените 'Aparts' на имя вашей коллекции
        const q = query(collectionRef, where("Name", "==", apartName));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
        } else {
          querySnapshot.forEach(async (doc) => {
            try {
              const apartData = doc.data();
              const folderRef = ref(storage, apartData.img + "/All");
              const result = await listAll(folderRef);

              const urlPromises = result.items.map(item => getDownloadURL(item));
              const urls = await Promise.all(urlPromises);
              if (urls) {
                setApart({
                  tower: apartData.tower,
                  price: apartData.price,
                  area: apartData.area,
                  floor: apartData.floor,
                  Name: apartData.Name,
                  description: apartData.description,
                  images: urls,
                  view: apartData.view,
                  sleep: apartData.sleep,
                  rooms: apartData.rooms,
                  location: apartData.location,
                  color: apartData.color,
                  discount: apartData.discount
                } as IApart)
              }
              setIsPending(false);
            } catch (error) {
              console.error('Ошибка при получении ссылок на фотографии:', error);
              return [];
            }
          });
        }
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    }
    fetchAparts();
    window.addEventListener("resize", CheckDevice)
    return () => {
      window.removeEventListener("resize", CheckDevice)
    };
  }, [])
  const handleScrollSlider = (right: boolean) => {
    if (slider.current) {
      let nextImage = currentImage;
      if (right && (currentImage < apart.images.length - 1)) {
        nextImage = currentImage + 1;
      }
      else if (!right && currentImage > 0) {
        nextImage = currentImage - 1;
      }
      setCurrentImage(nextImage)
      if (slider.current) {
        slider.current.scrollTo({
          left: ((device > 2 ? 700 : (pageWidth - 2 * mobilePadding))) * (nextImage), // Полная ширина содержимого
          behavior: 'smooth', // Гладкая анимация
        });
      }
    }
  }
  useEffect(() => {
    apart.images.map((image) => console.log(image))
  }, [apart])
  useEffect(() => {
    if (isOpenBookMobile) {
      const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.overflowY = "hidden";
      document.body.style.paddingRight = `${scrollBarWidth}px`;
    }
    else if (!isOpenBookMobile) {
      document.body.style.overflowY = "";
      document.body.style.paddingRight = "";
    }
  }, [isOpenBookMobile])

  useEffect(() => {
    if (isOpenGallery) {
      const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.overflowY = "hidden";
      document.body.style.paddingRight = `${scrollBarWidth}px`;
    }
    else {
      document.body.style.overflowY = "";
      document.body.style.paddingRight = "";
    }
  }, [isOpenGallery])

  return (
    <>
      <div className={styles.Apartment}>
        <Header />
        <div className={styles.PageContent}>
          <div className={styles.BookMobileWrapper}>
            <button onClick={() => {
              setIsOpenBookMobile(true);
            }} className={`${styles.Regular} ${styles.BookButtonMobile}`}>Забронировать от {apart.price} ₽</button>
          </div>
          <div className={`${styles.NameBlockMain} ${styles.NameBlock}`}>
            <a href={`${window.location.origin}/#Aparts`} className={`${styles.Bold} ${styles.Middle} ${styles.hide}`}>Апартаменты</a>
            <a href='#About' className={`${styles.Bold} ${styles.Middle}`}>{apart.Name}</a>
            {/* <a className={styles.Regular} href='#'>Об апартаментах</a> */}
          </div>
          <div className={styles.InfoBlock}>
            <div className={styles.Left}>
              <div className={`${styles.Block} ${styles.WrapperImagesBlock} ${styles.animation}`}>
                <div className={styles.ImagesBlock} ref={slider}
                  onScroll={updateScroll}>
                  <div className={styles.ScrollItem} style={{
                    width: apart.images.length * 800 + "px"
                  }}>
                    {apart.images.map((image, i) =>
                      <button onClick={() => {
                        setWantedImage(i);
                        setIsOpenGallery(true);
                      }} key={"image" + i} className={styles.ImagesWrapper}>
                        <img className={styles.Image} src={image} />
                        {/* <img className={styles.Background} src={image} ></img> */}
                      </button>
                    )}
                  </div>
                </div>
                <button onClick={() => handleScrollSlider(false)} className={`${styles.buttonSwipe} ${styles.leftSwipe}  ${!canGoLeftSlider ? styles.hide : ""}`}></button>
                <button onClick={() => handleScrollSlider(true)} className={`${styles.buttonSwipe} ${styles.rightSwipe}  ${!canGoRightSlider ? styles.hide : ""}`}></button>
              </div>
              <div id='About'></div>
              <div className={`${styles.Block} ${styles.AboutBlock} ${styles.animation}`}>
                <div className={styles.ShortInfo}>
                  <img className={`${styles.Icon}`} src='/images/sleep_black.svg' />
                  <span className={`${styles.Bold} ${styles.Middle}`}>Информация об апартаментах</span>
                  <div className={styles.List}>
                    <ul>
                      <li className={styles.infoText}><span className={styles.hide}>Этаж:</span><span> {apart.floor}</span></li>
                      <li className={styles.infoText}><span className={styles.hide}>Площадь:</span><span> {apart.area} м²</span></li>
                      <li className={styles.infoText}><span className={styles.hide}>Количество комнат:</span><span> {apart.rooms}</span></li>
                      <li className={styles.infoText}><span className={styles.hide}>Спальных мест:</span><span> {apart.sleep}</span></li>
                      <li className={styles.infoText}><span className={styles.hide}>{apart.location}:</span><span> {apart.tower}</span></li>
                      <li className={styles.infoText}><span className={styles.hide}>Вид:</span><span> {apart.view}</span></li>
                    </ul>
                  </div>
                </div>
                {/* <button className={`${styles.Regular} ${styles.More}`}>Подробнее</button> */}
              </div>
              <div className={`${styles.Block} ${styles.RulesBlock}  ${styles.animation}`}>
                <img src='/images/rules.svg' className={styles.icon}></img>
                <div className={styles.Bottom}></div>
                <span className={`${styles.Middle} ${styles.Bold}`}>Правила заселения</span>
                Цена в бронировании указана до 2-х человек, сверх этого количества доплата за гостей - 1000₽/гость/сутки<div className={styles.BigBR} />
                Заезд с 15:00<br />
                Выезд до 12:00<div className={styles.BigBR} />
                Поздний выезд и ранний заезд возможен по договорённости. Стоимость - 900₽/час

                <span className={`${styles.Bold} ${styles.Middle}`}>Залог </span>
                10,000₽ - стандартный<br />
                20,000₽ - при проведении мероприятий<div className={styles.BigBR} />
                Залог возвращается в день выезда до 18:00

                <span className={`${styles.Middle} ${styles.Bold}`}>Парковка</span>
                Вы можете оставить машину в башне "Neva Towers 1". Стоимость парковки: 4,500₽/сутки<div className={styles.BigBR} />

                <span className={`${styles.Bold} ${styles.Middle}`}>Документы </span>
                Во всех башнях проход по документам. Убедитесь, что у вас с собой паспорт или водительское удостоверение.<div className={styles.BigBR} />
                Для детей младше 14 лет - свидетельство о рождении<div className={styles.BigBR} />
                Отчетные документы предоставляются по запросу
              </div>
              <div className={`${styles.Block} ${styles.Contacts}  ${styles.animation}`}>
                <div className={styles.Top}>
                  <img src='/images/contacts.svg' className={styles.icon}></img>
                  <span className={`${styles.Middle} ${styles.Bold}`}>Контакты</span>
                  <ul className={`${styles.Text} ${styles.ContactsList}`}>
                    <li className={styles.phone}><a href='tel:+79299023710' target='_blank'>+7 929 902 3710</a></li>
                    <li className={styles.tg}><a href='https://t.me/cozzyaparts' target='_blank'>@cozzyaparts</a></li>
                    <li className={styles.mail}><a href='mailto:cozyaparts@yandex.ru' target='_blank'>cozyaparts@yandex.ru</a></li>
                  </ul>
                </div>
                <div className={styles.bottom}>
                  <a href='https://t.me/cozzyaparts' target='_blank'><img src='/images/tg_full.svg' /></a>
                  <a href="https://wa.me/79299023710" target="_blank"><img src='/images/whatsapp_full.svg' /></a>
                </div>
              </div>
            </div>
            <div className={`${styles.BookWrapper} ${styles.animation}`}>
              <Book apart={apart} />
            </div>
          </div>
        </div><div className={`${styles.ReveiwsBlock}  ${styles.animation}`} id='Reveiws'>
          <div className={styles.TopBlock}>
            <span className={`${styles.BlockTitle} ${styles.Big} ${styles.Bold} ${styles.animation}`}>Наши отзывы</span>
            <div className={`${styles.Info} ${styles.animation}`}>
              <img src='/images/info.svg' />
              <span>Мы размещаем ссылки на площадки бронирования, чтобы Вы могли ознакомиться с отзывами наших гостей и убедиться в качестве наших апартаментов.
                Однако, бронируя через наш сайт напрямую, Вам не придется оплачивать дополнительные комиссии, которые взимаются на сторонних платформах.</span>
            </div>
          </div>
          <div className={`${styles.Content} ${styles.animation}`}>
            <ReveiwsSlider />
          </div>
        </div>
        <Footer />
      </div>

      {isOpenBookMobile &&
        <div className={styles.WrapperFullService}>
          <div className={styles.Inner}>
            <Book apart={apart} setIsOpenBook={setIsOpenBookMobile} />
          </div>
        </div>
      }
      {
        isOpenGallery &&
        <Gallery setIsOpenGallery={setIsOpenGallery} startImage={wantedImage} images={apart.images} device={device} />
      }
    </>
  )
}

export default Apartment